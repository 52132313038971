<template>
    <div>
        <div class="clue_title">
            <span>
                <i class="shu"></i>
                往来记录
            </span>
        </div>
        <el-form ref="form" label-width="80px" style=" padding: 16px 35px;" label-position="left">
            <el-form-item label="往来时间">
                <el-date-picker
                    v-model="lineIntercourseList.intercourseTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width:240px;"
                    :picker-options="expireTimeOption"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="往来标题">
                <el-input
                    v-model="lineIntercourseList.intercourseTitle"
                    placeholder="请输入往来标题"
                    style="width:240px"
                    size="small"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
                <el-select
                    v-model="lineIntercourseList.contactWay"
                    size="small"
                    style="width:240px"
                    placeholder="请选择联系方式"
                    clearable
                >
                    <el-option label="电话" :value="1"></el-option>
                    <el-option label="微信" :value="2"></el-option>
                    <el-option label="邮箱" :value="3"></el-option>
                    <el-option label="QQ" :value="4"></el-option>
                    <el-option label="短信" :value="5"></el-option>
                    <el-option label="拜访" :value="6"></el-option>
                    <el-option label="官网表单" :value="8"></el-option>
                    <el-option label="座机" :value="9"></el-option>
                    <el-option label="其他" :value="7"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="往来内容">
                <el-input
                    style="width:240px"
                    type="textarea"
                    placeholder="请输入往来内容"
                    v-model="lineIntercourseList.intercourseContent"
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            lineIntercourseList: {
                contactWay: 1,
                intercourseTime: this.$handTime.getFormatDate(),
                intercourseContent: '',
                intercourseTitle: '',
            },
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
        };
    },
    components: {},
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue_title {
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #eeeeee;

    font-size: 14px;
    span {
        display: flex;
        .shu {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #2370eb;
            margin-right: 22px;
        }
    }
}
</style>
